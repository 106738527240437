/*
 * 업무구분: 고객
 * 화 면 명: MSPFS106M
 * 화면설명: 계좌정보조회2595
 * 작 성 일: 2023.03.30
 * 작 성 자: 이태흥
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">

    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- main start -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
            <div class="column w100p gap12">
              <div class="row w100p">
                <div class="left gap40 w100p">
                  <div class="wrap-input row">
                    <label class="emphasis"> 계좌번호 </label>
                    <mo-decimal-field 
                      numeric
                      ref="ed_accn_no"
                      class="w130 input-account"
                      :class="lv_ed_accn_no.error ? 'error' : ''"
                      v-model="lv_ed_accn_no.value"                       
                      @keyup="fn_SearchValid($event)"
                      @paste="fn_SearchValid($event, 'P')"
                      mask="#######-###"
                    />
                    <mo-dropdown 
                      ref="ed_acct_gb"
                      :items="lv_acct_list" 
                      v-model="lv_ed_acct_gb.value"
                      class="input-long" 
                      placeholder=" "
                      :disabled="lv_ed_acct_gb.disabled"
                    />
                    <mo-text-field 
                      v-model="lv_ed_cust_nm.value"
                      class="w100" 
                      disabled 
                    />
                  </div>
                  <!-- <div class="wrap-input row">
                    <label> 비밀번호 </label>
                    <mo-text-field 
                      type="password" 
                      class="w100" 
                    />
                  </div> -->
                </div>
                <div class="right">
                  <div class="wrap-button row">
                    <mo-button class="btn-clear" @click="fn_Init()"> 초기화 </mo-button>
                    <mo-button primary class="btn-inquiry" @click="fn_CustAccnInfoSearch()"> 조회 </mo-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info>
      <!-- fs-cust-info end -->

      <ur-box-container>
        <div class="wrap-table-title left align-center">
          <h2 class="table-title"> 계좌정보 </h2>
          <mo-button primary class="w90" v-if="lv_img_info_text" @click="fn_img_info_text_onclick()"> 정보노출고객 </mo-button> <!-- 2023.07.28 정지윤 금감원정보노출Btn 추가 -->
        </div>

        <div class="wrap-table">
          <table class="table th-wsN th-border-left th-white col1419">
            <tbody>
              <tr>
                <th> 그룹번호 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_group_no.value"
                      disabled 
                    />
                  </div>
                </td>
                <th> 펀드 </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field 
                      class="w80" 
                      v-model="lv_fund_cd.value"
                      disabled 
                    />
                    <mo-text-field 
                      class="w100p" 
                      v-model="lv_fund_nm.value" 
                      disabled 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th> 저축종류 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_save_tp.value"
                      disabled 
                    />
                  </div>
                </td>
                <th> 세제상품구분 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_tax_tp_gb.value" 
                      disabled 
                    />
                  </div>
                </td>
                <th> 재투자여부 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_reinv_yn.value" 
                      disabled 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th> 월저축금 </th>
                <td>
                  <div class="wrap-input row">
                    <!-- <mo-dropdown 
                      :items="saveMonth" 
                      v-model="lv_accum_amt.value" 
                      class="w150 aR" 
                      disabled 
                    /> -->
                    <mo-text-field 
                      v-model="lv_accum_amt.value" 
                      class="aR" 
                      disabled 
                    />                    
                  </div>
                </td>
                <th> 저축기간 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_save_term.value" 
                      class="aR" 
                      disabled 
                    />
                    <span>개월</span>
                  </div>
                </td>
                <th> 납입횟수 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_paidin_cnt.value" 
                      class="aR" 
                      disabled 
                    />
                    <span>회</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th> 계좌개설일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_open_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "
                      disabled 
                    />
                  </div>
                </td>
                <th> 계좌만기일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_exp_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "                      
                      disabled 
                    />
                  </div>
                </td>
                <th> 계좌해지일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_trmt_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "                      
                      disabled 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th> 납입금액(적립식) </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_orign_amt.value"
                      mask="number" 
                      disabled 
                    />
                  </div>
                  <p 
                    v-if="lv_tax_free_endday.value != ''"
                    class="exp-txt2"
                  >소득공제 일몰일 : {{ lv_tax_free_endday.value }}</p>
                </td>
                <th> 가입한도금액 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_join_limit_amt.value" 
                      class="aR" 
                      disabled 
                    />
                  </div>
                </td>
                <th> 이전최초가입일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_oring_open_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "                      
                      disabled 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th> 납입증명발급일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_issue_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "
                      disabled 
                    />
                  </div>
                </td>
                <th> 운용보고서 통보방법 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_mgr_report_recv_gb.value" 
                      disabled 
                    />
                  </div>
                </td>
                <th> 인터넷뱅킹여부 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_int_tr_yn.value" 
                      disabled 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th> 연금지급개시일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_pension_paym_open_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "
                      disabled 
                    />
                  </div>
                </td>
                <th> 연금지급기간 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_pension_paym_term.value" 
                      class="aR" 
                      disabled 
                    />
                    <span>개월</span>
                  </div>
                </td>
                <th> 연금지급주기 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_pension_paym_cycle.value" 
                      disabled 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th> CMS신청일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_req_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "
                      disabled 
                    />
                  </div>
                </td>
                <th> CMS이체시작일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_btrans_start_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "
                      disabled 
                    />
                  </div>
                </td>
                <th> CMS이체금액 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_btrans_amt.value"
                      mask="number" 
                      disabled 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th> CMS이체일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_btrans_dd.value" 
                      class="aR" 
                      disabled 
                    />
                    <span>일</span>
                  </div>
                </td>
                <th> CMS이체종료일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_btrans_end_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "
                      disabled 
                    />
                  </div>
                </td>
                <th> CMS이체은행명 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_btrans_bank_cd.value"
                      disabled 
                      />
                  </div>
                </td>
              </tr>
              <tr>
                <th> CMS이체은행계좌번호 </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_bnk_accn_no_encr.value" 
                      class="w150" 
                      disabled 
                    />
                    <mo-text-field 
                      v-model="lv_btrans_acct_nm.value" 
                      class="w100" 
                      disabled 
                    />
                  </div>
                </td>
                <th> CMS이체상태 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_btrans_state.value" 
                      disabled 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th> 자동대체신청일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_last_req_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "
                      disabled 
                    />
                  </div>
                </td>
                <th> 자동대체금액 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_alt_amt.value" 
                      mask="number" 
                      disabled 
                    />
                  </div>
                </td>
                <th> 자동대체일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_alt_btrans_dd.value" 
                      class="aR" 
                      disabled 
                    />
                    <span>일</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th> 자동대체 출금계좌번호 </th>
                <td colspan="2">
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_alt_acct_no.value" 
                      class="w150" 
                      disabled 
                    />
                    <mo-text-field 
                      v-model="lv_alt_acct_nm.value" 
                      class="w100" 
                      disabled 
                    />
                  </div>
                </td>
                <th> 연계계좌번호 </th>
                <td colspan="2">
                  <div class="wrap-input row">
                    <mo-text-field 
                      class="w150" 
                      v-model="lv_link_acct_no.value"
                      disabled 
                    />
                    <mo-text-field 
                      class="w100" 
                      v-model="lv_link_bank_nm.value"
                      disabled 
                    />
                  </div>
                </td>
              </tr>              
              <tr>
                <th> 급여이체신청일 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      v-model="lv_salary_req_dt.value" 
                      class="datepicker-w150" 
                      placeholder=" "
                      disabled 
                    />
                  </div>
                </td>
                <th> 급여이체신청금액 </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_salary_amte.value" 
                      mask="number" 
                      disabled 
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-table-title">
          <h2 class="table-title">
            잔고좌수
          </h2>
        </div>
        <div class="wrap-table">
          <table class="table th-wsN th-border-left th-white">
            <tbody>
              <tr>
                <th class="w187">잔고좌수</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_remn_qty.value" 
                      class="w100p aR" 
                      disabled 
                    />
                  </div>
                </td>
                <th class="w187">평가금액(세전)</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="lv_eval_amt.value" 
                      class="w100p aR" 
                      disabled 
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

    </main>
    <!-- main end -->

    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

    <!-- popup324 -->
    <msp-fs-324p
      ref="popup324"
      @fs-alert-popup="fn_AlertPopup"
      :popupObj="pPopup324Obj"
    ></msp-fs-324p>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

  import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)
  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
  import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통)
  import MSPFS324P from '~/src/ui/fs/MSPFS324P'             // 금감원개인정보노출 팝업


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS106M",
    screenId: "MSPFS106M",
    components: {
      'fs-header': FSHeader,
      'fs-alert-popup': FSAlertPopup,
      'fs-cust-info': FSCustInfo,
      'msp-fs-324p': MSPFS324P,    
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.fn_Init()

      this.$bizUtil.insSrnLog('MSPFS106M')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {
          title: '계좌정보조회',
          step: '',
        },
        pAlertPopupObj: {},
        lv_commCode: {},
        pCustInfoObj: {},
        pPopup324Obj: {},

        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,


        // 검색조건
        lv_ed_accn_no: {},        // 계좌번호
        lv_ed_acct_gb: {},        // 계좌종류
        lv_ed_cust_nm: {},        // 고객명
      

        // 정보노출고객버튼
        lv_img_info_text: false,  // 정보노출고객버튼


        // 계좌정보
        lv_cust_info: {},         // 고객정보
        lv_acct_list: [],         // 계좌조회목록

        lv_group_no: {},          // 그룹번호
        lv_fund_cd: {},           // 펀드코드
        lv_fund_nm: {},           // 펀드명

        lv_save_tp: {},           // 저축종류
        lv_tax_tp_gb: {},         // 세제상품구분
        lv_reinv_yn: {},          // 재투자여부

        lv_accum_amt: {},         // 월저축금
        lv_save_term: {},         // 저축기간
        lv_paidin_cnt: {},        // 납입횟수

        lv_open_dt: {},           // 계좌개설일
        lv_exp_dt: {},            // 계좌만기일
        lv_trmt_dt: {},           // 계좌해지일
 
        lv_orign_amt: {},         // 납입금액(적립식)
        lv_tax_free_endday: {},   // 소득공제 일몰일(hidden)
        lv_join_limit_amt: {},    // 가입한도금액
        lv_oring_open_dt: {},     // 이전최초가입일

        lv_issue_dt: {},            // 납입증명발급일
        lv_mgr_report_recv_gb: {},  // 운용보고서 통보방법
        lv_int_tr_yn: {},           // 인터넷뱅킹여부

        lv_pension_paym_open_dt: {},  // 연금지급개시일
        lv_pension_paym_term: {},     // 연금지급기간
        lv_pension_paym_cycle: {},    // 연금지급주기

        lv_req_dt: {},                // CMS신청일
        lv_btrans_start_dt: {},       // CMS이체시작일
        lv_btrans_amt: {},            // CMS이체금액

        lv_btrans_dd: {},             // CMS이체일
        lv_btrans_end_dt: {},         // CMS이체종료일
        lv_btrans_bank_cd: {},        // CMS이체은행명
        
        lv_bnk_accn_no_encr: {},      // CMS이체은행계좌번호
        lv_btrans_acct_nm: {},        // CMS이체은행계좌번호성명
        lv_btrans_state: {},          // CMS이체상태

        lv_last_req_dt: {},           // 자동대체신청일
        lv_alt_amt: {},               // 자동대체금액
        lv_alt_btrans_dd: {},         // 자동대체일
        lv_alt_acct_no: {},           // 자동대체 출금계좌번호
        lv_alt_acct_nm: {},           // 자동대체 출금계좌번호성명

        lv_link_acct_no: {},          // 연계계좌번호
        lv_link_bank_nm: {},          // 연계계좌번호은행명

        lv_salary_req_dt: {},         // 급여이체신청일
        lv_salary_amte: {},           // 급여이체신청금액

        // 잔고좌수
        lv_remn_qty: {},              // 잔고좌수
        lv_eval_amt: {},              // 평가금액(세전)

        // 고객정보 펼치기/접기
        isActive: false,

        // 로그인정보
        lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
        // 정보노출 
        lv_cust_fss: {},


      
        /***********************************************************************************
         * F10570143 : 고객별보유계좌정보조회

         * F10570144 : 정기지급형계좌정보조회 - 기능삭제
         * F10570145 : 매입/환매증거좌수조회 - 기능삭제
         * F10570148 : CMS변경이력조회 - 기능삭제
         * F10570151 : 연금지급신청정보조회 - 기능삭제
         * F10570153 : 연금지급계좌의연도별연간수령한도조회 - 기능삭제
         ***********************************************************************************/
        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = []
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },

      fn_SetCommCodeResult(pResult) {
        this.lv_commCode = pResult
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS106M] fn_Init...')

        // 검색조건
        this.lv_ed_accn_no            = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_accn_no')      // 계좌번호
        this.lv_ed_acct_gb            = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb')       // 계좌종류
        this.lv_ed_cust_nm            = FSCommUtil.gfn_dataSet(1)                                   // 고객명

        this.lv_cust_info             = {}                                                          // 고객정보
        this.lv_acct_list             = []                                                          // 계좌조회목록

        // 고객정보 초기화
        this.$refs.fsCustInfo.fn_Init()
        
        // 정보노출고객버튼
        this.lv_img_info_text         = false                                                       // 정보노출고객버튼

        // 계좌정보
        this.lv_group_no              = FSCommUtil.gfn_dataSet(1)                                   // 그룹번호
        this.lv_fund_cd               = FSCommUtil.gfn_dataSet(1)                                   // 펀드코드
        this.lv_fund_nm               = FSCommUtil.gfn_dataSet(1)                                   // 펀드명

        this.lv_save_tp               = FSCommUtil.gfn_dataSet(1)                                   // 저축종류
        this.lv_tax_tp_gb             = FSCommUtil.gfn_dataSet(1)                                   // 세제상품구분
        this.lv_reinv_yn              = FSCommUtil.gfn_dataSet(1)                                   // 재투자여부

        this.lv_accum_amt             = FSCommUtil.gfn_dataSet(1)                                   // 월저축금
        this.lv_save_term             = FSCommUtil.gfn_dataSet(1)                                   // 저축기간
        this.lv_paidin_cnt            = FSCommUtil.gfn_dataSet(1)                                   // 납입횟수

        this.lv_open_dt               = FSCommUtil.gfn_dataSet(1)                                   // 계좌개설일
        this.lv_exp_dt                = FSCommUtil.gfn_dataSet(1)                                   // 계좌만기일
        this.lv_trmt_dt               = FSCommUtil.gfn_dataSet(1)                                   // 계좌해지일

        this.lv_orign_amt             = FSCommUtil.gfn_dataSet(1)                                   // 납입금액(적립식)
        this.lv_tax_free_endday       = FSCommUtil.gfn_dataSet(1)                                   // 소득공제 일몰일(hidden)
        this.lv_join_limit_amt        = FSCommUtil.gfn_dataSet(1)                                   // 가입한도금액
        this.lv_oring_open_dt         = FSCommUtil.gfn_dataSet(1)                                   // 이전최초가입일

        this.lv_issue_dt              = FSCommUtil.gfn_dataSet(1)                                   // 납입증명발급일
        this.lv_mgr_report_recv_gb    = FSCommUtil.gfn_dataSet(1)                                   // 운용보고서 통보방법
        this.lv_int_tr_yn             = FSCommUtil.gfn_dataSet(1)                                   // 인터넷뱅킹여부

        this.lv_pension_paym_open_dt  = FSCommUtil.gfn_dataSet(1)                                   // 연금지급개시일
        this.lv_pension_paym_term     = FSCommUtil.gfn_dataSet(1)                                   // 연금지급기간
        this.lv_pension_paym_cycle    = FSCommUtil.gfn_dataSet(1)                                   // 연금지급주기

        this.lv_req_dt                = FSCommUtil.gfn_dataSet(1)                                   // CMS신청일
        this.lv_btrans_start_dt       = FSCommUtil.gfn_dataSet(1)                                   // CMS이체시작일
        this.lv_btrans_amt            = FSCommUtil.gfn_dataSet(1)                                   // CMS이체금액

        this.lv_btrans_dd             = FSCommUtil.gfn_dataSet(1)                                   // CMS이체일
        this.lv_btrans_end_dt         = FSCommUtil.gfn_dataSet(1)                                   // CMS이체종료일
        this.lv_btrans_bank_cd        = FSCommUtil.gfn_dataSet(1)                                   // CMS이체은행명

        this.lv_bnk_accn_no_encr      = FSCommUtil.gfn_dataSet(1)                                   // CMS이체은행계좌번호
        this.lv_btrans_acct_nm        = FSCommUtil.gfn_dataSet(1)                                   // CMS이체은행계좌번호성명
        this.lv_btrans_state          = FSCommUtil.gfn_dataSet(1)                                   // CMS이체상태

        this.lv_last_req_dt           = FSCommUtil.gfn_dataSet(1)                                   // 자동대체신청일
        this.lv_alt_amt               = FSCommUtil.gfn_dataSet(1)                                   // 자동대체금액
        this.lv_alt_btrans_dd         = FSCommUtil.gfn_dataSet(1)                                   // 자동대체일
        this.lv_alt_acct_no           = FSCommUtil.gfn_dataSet(1)                                   // 자동대체 출금계좌번호
        this.lv_alt_acct_nm           = FSCommUtil.gfn_dataSet(1)                                   // 자동대체 출금계좌번호성명

        this.lv_link_acct_no          = FSCommUtil.gfn_dataSet(1)                                   // 연계계좌번호
        this.lv_link_bank_nm          = FSCommUtil.gfn_dataSet(1)                                   // 연계계좌번호은행명

        this.lv_salary_req_dt         = FSCommUtil.gfn_dataSet(1)                                   // 급여이체신청일
        this.lv_salary_amte           = FSCommUtil.gfn_dataSet(1)                                   // 급여이체신청금액

        // 잔고좌수
        this.lv_remn_qty              = FSCommUtil.gfn_dataSet(1)                                   // 잔고좌수
        this.lv_eval_amt              = FSCommUtil.gfn_dataSet(1)                                   // 평가금액(세전)
		
		// 정보노출
        this.lv_cust_fss              = {}
      },


      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },


      /******************************************************************************
       * Function명 : fn_IsActive
       * 설명       : 고객정보 펼치기/접기
       ******************************************************************************/
      fn_IsActive() {
        this.isActive = !this.isActive;
      },


      /******************************************************************************
       * Function명 : fn_CustInfo, fn_SetEdCustName
       * 설명       : 고객정보조회
       ******************************************************************************/
      fn_CustInfo() {
        console.log('fn_CustInfo.....')

        this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
        this.$refs.fsCustInfo.fn_CustInfoSearch()
      },

      fn_SetEdCustName(val) {
        // 고객명
        this.lv_ed_cust_nm.value = val
      },


      /******************************************************************************
       * Function명 : fn_AccnNoList, fn_AccnNoListResult
       * 설명       : 계좌목록조회
       ******************************************************************************/
      fn_AccnNoList(pAccnNo) {
        console.log('fn_AccnNoList.....')

        let t_data = {bfcer_cstgr_no : pAccnNo}
        FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult)
      },

      fn_AccnNoListResult(pResultData) {
        console.log('fn_AccnNoListResult.....')

        let t_data = pResultData.data.sy000000_O_00VO
        console.log(t_data)

        // 계좌목록조회 초기화
        this.lv_acct_list = []
        this.lv_ed_acct_gb.disabled = true

        if( t_data.length > 0 ) {
          t_data.forEach((item, idx) => {
            this.lv_acct_list.push({value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no})
          })
          
          // 계좌목록조회 결과 첫번째 선택 및 활성화
          this.lv_ed_acct_gb.value = 0
          this.lv_ed_acct_gb.disabled = false

          // 계좌정보조회 결과 초기화
          this.fn_CustAccnInfoClear()

          // 고객정보조회
          this.fn_CustInfo()
          
        } else {
          
          let t_popupObj = {content: "검색할 계좌가 없습니다."}
          this.fn_AlertPopup(0, t_popupObj)
        }
      },


      /******************************************************************************
       * Function명 : fn_CustAccnInfoSearch, fn_CustAccnInfoSearchResult
       * 설명       : 고객별보유계좌정보조회
       ******************************************************************************/
      fn_CustAccnInfoSearch() {
        console.log('fn_CustAccnInfoSearch.....')

        this.lv_ed_accn_no.error = false

        let t_accn_no = this.lv_ed_accn_no.value ? this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0,10) : ''

        if( FSCommUtil.gfn_isNull(t_accn_no) ) {
          FSCommUtil.gfn_validate(this, '계좌번호를 입력해 주십시오.')
          this.lv_ed_accn_no.error = true
          this.$refs['ed_accn_no'].focus()
          return          
        } else if( FSCommUtil.gfn_length(t_accn_no) != 10 || !FSCommUtil.gfn_isNum(t_accn_no) ) {
          FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
          this.lv_ed_accn_no.error = true
          this.$refs['ed_accn_no'].focus()
          return
        } else if( this.lv_acct_list.length == 0 ) {
          FSCommUtil.gfn_validate(this, '검색할 계좌가 없습니다.')
          return
        }

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570143_S'
        this.eaiCommObj.params = {
          acct_no         : this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue,
          pbk_no          : t_accn_no,
          secure_passv    : '',
          biz_dt          : this.lv_basInfo.busyDate,
          depo_fund_cd    : '100000',
          mngFofYn        : 'Y',
          secure_encsymm  : '',
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustAccnInfoSearchResult)
      },

      fn_CustAccnInfoSearchResult(pResultData) {
        console.log('fn_CustAccnInfoSearchResult.....')

        let t_data = pResultData.data.rp259500_O_00VO
        console.log(t_data)

        if( pResultData.data.error_msg == '809900' && t_data.length > 0 ) {

          // 계좌정보
          let t_accnInfo = t_data[0]
          let t_accn_no  = this.lv_ed_accn_no.value

          this.lv_group_no.value              = t_accn_no                   // 그룹번호
          this.lv_fund_cd.value               = t_accnInfo.fund_cd          // 펀드코드
          this.lv_fund_nm.value               = t_accnInfo.fund_nm          // 펀드명

          this.lv_save_tp.value               = t_accnInfo.save_tp          // 저축종류
          this.lv_tax_tp_gb.value             = t_accnInfo.tax_tp_gb        // 세제상품구분
          this.lv_reinv_yn.value              = t_accnInfo.reinv_yn         // 재투자여부
          
          this.lv_accum_amt.value             = this.$bizUtil.numberWithCommas(t_accnInfo.accum_amt)  // 월저축금
          this.lv_save_term.value             = t_accnInfo.save_term                                  // 저축기간
          this.lv_paidin_cnt.value            = t_accnInfo.paidin_cnt                                 // 납입횟수

          this.lv_open_dt.value               = FSCommUtil.gfn_dateReplace(1, t_accnInfo.open_dt)     // 계좌개설일
          this.lv_exp_dt.value                = FSCommUtil.gfn_dateReplace(1, t_accnInfo.exp_dt)      // 계좌만기일
          this.lv_trmt_dt.value               = FSCommUtil.gfn_dateReplace(1, t_accnInfo.trmt_dt)     // 계좌해지일

          this.lv_orign_amt.value             = this.$bizUtil.numberWithCommas(t_accnInfo.orign_amt)            // 납입금액(적립식)
          if( t_accnInfo.tax_tp_gb_cd == '15' ) {
            this.lv_tax_free_endday.value     = FSCommUtil.gfn_dateReplace(1, t_accnInfo.tax_free_endday)       // 소득공제 일몰일(hidden)
          }
          this.lv_join_limit_amt.value        = this.$bizUtil.numberWithCommas(t_accnInfo.join_limit_amt)       // 가입한도금액
          this.lv_oring_open_dt.value         = FSCommUtil.gfn_dateReplace(1, t_accnInfo.oring_open_dt)         // 이전최초가입일

          this.lv_issue_dt.value              = FSCommUtil.gfn_dateReplace(1, t_accnInfo.issue_dt)              // 납입증명발급일
          this.lv_mgr_report_recv_gb.value    = t_accnInfo.mgr_report_recv_gb                                   // 운용보고서 통보방법
          this.lv_int_tr_yn.value             = t_accnInfo.int_tr_yn                                            // 인터넷뱅킹여부

          this.lv_pension_paym_open_dt.value  = FSCommUtil.gfn_dateReplace(1, t_accnInfo.pension_paym_open_dt)  // 연금지급개시일
          this.lv_pension_paym_term.value     = t_accnInfo.pension_paym_term                                    // 연금지급기간
          this.lv_pension_paym_cycle.value    = t_accnInfo.pension_paym_cycle                                   // 연금지급주기

          this.lv_req_dt.value                = FSCommUtil.gfn_dateReplace(1, t_accnInfo.req_dt)                // CMS신청일
          this.lv_btrans_start_dt.value       = FSCommUtil.gfn_dateReplace(1, t_accnInfo.btrans_start_dt)       // CMS이체시작일
          this.lv_btrans_amt.value            = this.$bizUtil.numberWithCommas(t_accnInfo.btrans_amt)           // CMS이체금액

          this.lv_btrans_dd.value             = t_accnInfo.btrans_dd                                            // CMS이체일
          this.lv_btrans_end_dt.value         = FSCommUtil.gfn_dateReplace(1, t_accnInfo.btrans_end_dt)         // CMS이체종료일
          this.lv_btrans_bank_cd.value        = t_accnInfo.btrans_bank_cd                                       // CMS이체은행명

          this.lv_bnk_accn_no_encr.value      = t_accnInfo.bnk_accn_no_encr                                     // CMS이체은행계좌번호
          this.lv_btrans_acct_nm.value        = t_accnInfo.btrans_acct_nm                                       // CMS이체은행계좌번호성명          
          this.lv_btrans_state.value          = t_accnInfo.btrans_state                                         // CMS이체상태

          this.lv_last_req_dt.value           = FSCommUtil.gfn_dateReplace(1, t_accnInfo.last_req_dt)           // 자동대체신청일
          this.lv_alt_amt.value               = this.$bizUtil.numberWithCommas(t_accnInfo.alt_amt)              // 자동대체금액
          this.lv_alt_btrans_dd.value         = t_accnInfo.alt_btrans_dd                                        // 자동대체일
          this.lv_alt_acct_no.value           = FSCommUtil.gfn_replace_accn_no(t_accnInfo.alt_acct_no)          // 자동대체 출금계좌번호
          this.lv_alt_acct_nm.value           = t_accnInfo.alt_acct_nm                                          // 자동대체 출금계좌번호성명

          this.lv_link_acct_no.value          = t_accnInfo.link_acct_no                                         // 연계계좌번호
          this.lv_link_bank_nm.value          = t_accnInfo.link_bank_nm                                         // 연계계좌번호은행명

          this.lv_salary_req_dt.value         = FSCommUtil.gfn_dateReplace(1, t_accnInfo.salary_req_dt)         // 급여이체신청일
          this.lv_salary_amte.value           = this.$bizUtil.numberWithCommas(t_accnInfo.salary_amt)           // 급여이체신청금액

          // 잔고좌수
          this.lv_remn_qty.value              = this.$bizUtil.numberWithCommas(t_accnInfo.remn_qty)             // 잔고좌수
          this.lv_eval_amt.value              = this.$bizUtil.numberWithCommas(t_accnInfo.eval_amt)             // 평가금액(세전)

        } else {

          let t_popupObj = {content: "조회에 실패하였습니다."}
          this.fn_AlertPopup(0, t_popupObj)
        }
      },

      fn_CustAccnInfoClear() {
        console.log('fn_CustAccnInfoClear.....')

        this.lv_group_no.value              = ''  // 그룹번호
        this.lv_fund_cd.value               = ''  // 펀드코드
        this.lv_fund_nm.value               = ''  // 펀드명

        this.lv_save_tp.value               = ''  // 저축종류
        this.lv_tax_tp_gb.value             = ''  // 세제상품구분
        this.lv_reinv_yn.value              = ''  // 재투자여부
        
        this.lv_accum_amt.value             = ''  // 월저축금
        this.lv_save_term.value             = ''  // 저축기간
        this.lv_paidin_cnt.value            = ''  // 납입횟수

        this.lv_open_dt.value               = ''  // 계좌개설일
        this.lv_exp_dt.value                = ''  // 계좌만기일
        this.lv_trmt_dt.value               = ''  // 계좌해지일

        this.lv_orign_amt.value             = ''  // 납입금액(적립식)
        this.lv_tax_free_endday.value       = ''  // 소득공제 일몰일(hidden)
        this.lv_join_limit_amt.value        = ''  // 가입한도금액
        this.lv_oring_open_dt.value         = ''  // 이전최초가입일

        this.lv_issue_dt.value              = ''  // 납입증명발급일
        this.lv_mgr_report_recv_gb.value    = ''  // 운용보고서 통보방법
        this.lv_int_tr_yn.value             = ''  // 인터넷뱅킹여부

        this.lv_pension_paym_open_dt.value  = ''  // 연금지급개시일
        this.lv_pension_paym_term.value     = ''  // 연금지급기간
        this.lv_pension_paym_cycle.value    = ''  // 연금지급주기

        this.lv_req_dt.value                = ''  // CMS신청일
        this.lv_btrans_start_dt.value       = ''  // CMS이체시작일
        this.lv_btrans_amt.value            = ''  // CMS이체금액

        this.lv_btrans_dd.value             = ''  // CMS이체일
        this.lv_btrans_end_dt.value         = ''  // CMS이체종료일
        this.lv_btrans_bank_cd.value        = ''  // CMS이체은행명

        this.lv_bnk_accn_no_encr.value      = ''  // CMS이체은행계좌번호
        this.lv_btrans_acct_nm.value        = ''  // CMS이체은행계좌번호성명
        this.lv_btrans_state.value          = ''  // CMS이체상태

        this.lv_last_req_dt.value           = ''  // 자동대체신청일
        this.lv_alt_amt.value               = ''  // 자동대체금액
        this.lv_alt_btrans_dd.value         = ''  // 자동대체일
        this.lv_alt_acct_no.value           = ''  // 자동대체 출금계좌번호
        this.lv_alt_acct_nm.value           = ''  // 자동대체 출금계좌번호성명

        this.lv_link_acct_no.value          = ''  // 연계계좌번호
        this.lv_link_bank_nm.value          = ''  // 연계계좌번호은행명

        this.lv_salary_req_dt.value         = ''  // 급여이체신청일
        this.lv_salary_amte.value           = ''  // 급여이체신청금액

        // 잔고좌수
        this.lv_remn_qty.value              = ''  // 잔고좌수
        this.lv_eval_amt.value              = ''  // 평가금액(세전)
      },      


      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
       ******************************************************************************/
      fn_SearchValid(event, flag) {
        console.log('fn_SearchValid.....')

				let t_accn_no = this.lv_ed_accn_no.value
        // if( flag == 'P' ) {
        //   t_accn_no = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,10)
        // } else {
        //   if( !FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value) ) {
        //     t_accn_no = this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0,10)
        //   }
        // } 

        if( FSCommUtil.gfn_length(t_accn_no) >= 10 ) {
          this.lv_ed_accn_no.error = false

          if( !FSCommUtil.gfn_isNum(t_accn_no) ) {
            FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
            this.lv_ed_accn_no.error = true
            this.$refs['ed_accn_no'].focus()
            return 

          } else {
            if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || flag == 'P' || this.isMobile ) {
              // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

              // 금감원정보노출사건번호 체크
              let t_data = {}
              t_data.bfcer_cust_no = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
              FSInfoUtil.commInfo(this, 4, t_data, this.fn_search_callback_fssInfoExpsrEvntNo)

              // 계좌목록조회
              // this.fn_AccnNoList(t_accn_no)
            }
          }
        } else if( t_accn_no.length > 7 || event.key == 'Delete' || event.key == 'Backspace' ) {
          // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

          // 계좌목록/고객명 초기화
          this.lv_acct_list = []
          this.lv_ed_acct_gb.disabled = true
          this.lv_ed_cust_nm.value = ''

          // 고객정보 펼치기 정보 초기화
          this.$refs.fsCustInfo.fn_Init()
        }

      },

      // 2023.07.28 정지윤 금감원정보노출Btn 추가
      /******************************************************************************
       * Function명 : fn_search_callback_fssInfoExpsrEvntNo
       * 설명       : 금감원정보노출사건번호 체크
       ******************************************************************************/
      fn_search_callback_fssInfoExpsrEvntNo(pResultData) {
        console.log(pResultData)
        if ( !FSCommUtil.gfn_isNull(pResultData.data.eaf1MDI0083_O_OOVO) ) {
          this.lv_cust_fss = pResultData.data.eaf1MDI0083_O_OOVO[0]
          console.log(this.lv_cust_fss)
        }
        // 정보노출고객 버튼 활성화
        if( !FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_cust_fss.fssInfoExpsrEvntNo)) ) {
          this.lv_img_info_text = true
        }

        // 계좌목록조회
        this.fn_AccnNoList(this.lv_ed_accn_no.value)

      },
      /******************************************************************************
       * Function명 : fn_img_info_text_onclick
       * 설명       : 정보노출고객 버튼
       ******************************************************************************/
      fn_img_info_text_onclick(){
        console.log('fn_img_info_text_onclick...')

        // 금감원정보노출사건번호 체크
        if( FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_cust_fss.fssInfoExpsrEvntNo)) ) {
          // do nothing
        } else {
          // 실명번호(custRrn 값) 뒷자리 마스킹 처리시 정상 수정 안될수 있음
          let t_obj = {
            event_no        : this.lv_cust_fss.fssInfoExpsrEvntNo,
            event_cust_nm   : this.lv_cust_fss.custNm,
            event_reg_no    : FSCommUtil.gfn_substr(this.lv_cust_fss.custRrn, 0, 6) + '-' + FSCommUtil.gfn_substr(this.lv_cust_fss.custRrn, 6, 1) + '******',
            tel_no          : this.lv_cust_fss.telno,
            hp_no           : this.lv_cust_fss.celno,
            sago_cd         : this.lv_cust_fss.fssInfoExpsrAcdtCd,
            sago_txt        : this.lv_cust_fss.fssInfoExpsrAcdtNm,
            apnd_cmpy       : this.lv_cust_fss.fssInfoExpsrRegCoNm,
            apnd_ymd        : this.lv_cust_fss.regDtm,
          }
          // 금감원개인정보노출 조회 팝업
          this.pPopup324Obj = t_obj
          this.$refs.popup324.fn_Open()
          // this.fn_OpenPopup('MSPFS324P', t_obj)
        }
      },


    }
  }
</script>
<style scoped>
</style>